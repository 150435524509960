import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import routes from "./routes";
const router = new VueRouter({
  mode: "hash",
  routes: routes,
});
// 重写路由push方法
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return routerPush.call(this, location, onResolve, onReject);
  return routerPush.call(this, location).catch((error) => error);
};
// 设置路由拦截
// router.beforeEach((to, from, next) => {
//   // 1. 判断是不是登录页面
//   // 是登录页面
//   if (to.path === "/login") {
//     next();
//   } else {
//     // 不是登录页面
//     // 2. 判断 是否登录过
//     let token = localStorage.getItem("skey");
//     token ? next() : next("/login");
//   }
// });
export default router;
