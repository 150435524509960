<!--  -->
<template>
    <div class='footer-container '>
        <div class="container">
            <div class="info-container">
                <div class="item">
                    <p class="title">关于我们</p>
                    <div class="i-t">
                        <a href="">中心简介</a>
                        <a href="">联系我们</a>
                        <a href="">帮助中心</a>
                    </div>
                </div>
                <div class="item">
                    <p class="title">家长指南</p>
                    <div class="i-t">
                        <router-link to="/compass">操作指南</router-link>
                        <a href="">投诉建议</a>
                    </div>
                </div>
                <div class="item">
                    <p class="title">合作伙伴</p>
                    <div class="i-t">
                        <router-link to="/description/organization">西南大学</router-link>
                        <router-link to="/description/organization">贵阳幼专</router-link>
                        <router-link to="/description/organization">其他</router-link>
                    </div>
                </div>
                <div class="item">
                    <p class="title">培训资源</p>
                    <div class="i-t">
                        <a
                            href="https://www.sryzh.com/course/explore?subCategory=&selectedthirdLevelCategory=&tag[tags][4]=24&filter[type]=all&filter[price]=all&filter[currentLevelId]=all&orderBy=latest">云智慧</a>
                    </div>
                </div>
                <div class="item-tell">
                    <div class="phone-icon">
                        <img src="../../assets/images/phone.png" alt="">
                    </div>
                    <div class="tell">
                        <p>咨询热线</p>
                        <p>023-868-98337</p>
                    </div>
                </div>
                <div class="item">
                    <img class="logo" src="../../assets/images/logo.jpg" alt="">
                </div>
            </div>
            <div class="bottoms">
                <div style="cursor: pointer;"> <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备2021010464号-1</a> </div>
                <div
                    style="width: 2px; height: 14px; background-color: rgb(152, 152, 152); margin-left: 10px; margin-right: 10px; ">
                </div>
                <div><a target="_blank"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002503391"
                        style="display: flex; align-items: center; text-decoration: none;"><img
                            src="http://www.beian.gov.cn/img/new/gongan.png" style="float: left;">
                        <p
                            style="float: left; margin-right: 5px; color: rgb(147, 147, 147); font-size: 12px !important;">
                            渝公网安备 50019002503391号</p>
                    </a></div>
                <div
                    style="width: 2px; height: 14px; background-color: rgb(152, 152, 152); margin-left: 10px; margin-right: 10px; ">
                </div>
                <div style="cursor: pointer;"> 出版物经营许可证 新出发2022批次第006号 </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //方法集合
    methods: {

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang='less' scoped>
.footer-container {

    padding: 60px 0 40px 0;
    background: #333333;
    color: #9F9F9F;

    .info-container {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #989898;
    }

    .item {
        p {
            color: #D5D5D5;
            font-size: 16px;
            margin-bottom: 20px;
        }

        .logo {
            width: 140px;
            height: 140px;
        }

        .i-t {
            display: flex;
            flex-direction: column;

            a {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
    }

    .item-tell {
        display: flex;

        .phone-icon {
            width: 32px;
            height: 32px;
            margin-right: 10px;
            background: rgba(255, 255, 255, 0.12);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
            }

            p {
                padding-bottom: 4px;
            }
        }
    }
    .bottoms{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
}
</style>