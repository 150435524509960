const routes = [
  {
    path: "/",
    redirect: (to) => `/index`,
    meta: {
      title: "",
    },
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/Index/Index.vue"),
    meta: {
      showHeader: true,
    },
  },
  {
    path: "/description",
    name: "description",
    redirect: "/description/basics",
    component: () => import("@/views/Description/Description.vue"),
    meta: {
      showHeader: true,
    },
    children: [
      // 理论基础
      {
        path: "basics",
        name: "basics",
        component: () => import("@/views/Description/Basics.vue"),
      },
      // 课程资源
      {
        path: "resource",
        name: "resource",
        component: () => import("@/views/Description/Resource.vue"),
      },
      // 课程理念
      {
        path: "idea",
        name: "idea",
        component: () => import("@/views/Description/Idea.vue"),
      },
      // 培养目标
      {
        path: "target",
        name: "target",
        component: () => import("@/views/Description/Target.vue"),
      },
      // 课程框架
      {
        path: "frame",
        name: "frame",
        component: () => import("@/views/Description/Frame.vue"),
      },
      // 发展历程
      {
        path: "course",
        name: "course",
        component: () => import("@/views/Description/Course.vue"),
      },
      // 合作机构
      {
        path: "organization",
        name: "organization",
        component: () => import("@/views/Description/Organization.vue"),
      },
     
    ],
  },
  {
    path: "/consult",
    name: "consult",
    component: () => import("@/views/Consult/Consult.vue"),
    meta: {
      showHeader: true,
    },
  },
  {
    path: "/compass",
    name: "compass",
    component: () => import("@/views/Compass/Compass.vue"),
    meta: {
      showHeader: true,
    },
  },
  {
    path: "/train",
    name: "train",
    component: () => import("@/views/Train/Train.vue"),
    meta: {
      showHeader: true,
    },
  },
  {

    path: "/about",
    name: "about",
    component: () => import("@/views/About/About.vue"),
    meta: {
      showHeader: true,
    },
  },
];
export default routes;
