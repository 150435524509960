<!-- 头部 -->
<template>
  <div class="header-container">
    <div class="container">
      <el-row type="flex" justify="space-between">
        <el-col :span="3">
          <img src="../../assets/images/logo.png" alt="">
        </el-col>
        <el-col :span="21">
          <div class="nav-list">
            <el-row type="flex" justify="end">
              <el-col :span="3.5" v-for="item in items" :key="item.link" >
                <router-link :to="{ name: item.name }" active-class="selected"
                  exact-active-class="">
                  <div class="item">{{ item.title }}</div>
                </router-link>
              </el-col>
            </el-row>

          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
  },
  data() {
    //这里存放数据
    return {
      items: [
        {
          name: "index",
          title: "首页",
          icon: "home",
          exact: true, // 激活状态是否精确匹配
        },
        {
          name: "description",
          title: "课程介绍",
          icon: "blog",
          exact: false, // 激活状态是否精确匹配
        },
        {
          name: "consult",
          title: "动态资讯",
          icon: "about",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "train",
          title: "培训资源",
          icon: "code",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "compass",
          title: "家长指南",
          icon: "chat",
          // exact: true, // 激活状态是否精确匹配
        },
        {
          name: "about",
          title: "关于我们",
          icon: "chat",
          // exact: true, // 激活状态是否精确匹配
        },
      ],
    };
  },
  //方法集合
  methods: {


  },
  created() {

  },
  mounted() {


  }
};
</script>
<style lang="less" scoped>
.header-container {
  // position: fixed;
  width: 100%;
  height: 68px;
  background: #FECB55;
  z-index: 99;

  .container {
    margin: 0 auto;
    display: block;
    .nav-list {
      // padding: 0 12px;
      text-align: center;
      .selected {
        color: #86C138;
        border-bottom: 2px solid #86C138;
        display: block;
        .item {
          padding-bottom: 20px;
        }
      }
      .item {
        padding: 22px 30px;
        font-size: 18px;

        &:hover {
          color: #86C138;
          background: #fff;
        }

      }
    }
  }
}
</style>
