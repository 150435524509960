<template>
  <div id="app">
    <Header></Header>

    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/HeaderTop/HeaderTop.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {

    }
  }
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  // min-width: 1400px;
  box-sizing: border-box;

  // background-color: #bfa;
  .el-header{
    padding: 0;
  }
  .el-main{
    padding: 0;
    width: 100%;
  }
}

</style>
