import Vue from 'vue'
import App from './App.vue'
import router from "@/router/index.js" //引入路由器
Vue.config.productionTip = false
import "./common/css/reset.css"; //引入重置样式表
// import ElementUI from 'element-ui'; //引入饿了么包
import 'element-ui/lib/theme-chalk/index.css';//引入饿了么样式
// Vue.use(ElementUI);
// 把vuex仓库引入并挂载在Vue实例上
import store from './store/index'

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Message,
  Image,
  Row,
  Col,
  Container,
  Header,
  Main,
  Footer,
  Tabs,
  TabPane,
  InfiniteScroll 

} from 'element-ui'

Vue.prototype.$message = Message;

Vue.use(Button);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Image);
Vue.use(Row);
Vue.use(Col);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(InfiniteScroll);

new Vue({
  render: h => h(App),
  router,
  store // 挂载上去
}).$mount('#app')

// console.log('vue实例对象',vvvue);